import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { School } from 'models/School';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { ReactComponent as DuplicateIcon } from 'icons/Duplicate.icon.svg';
import env from 'env';
import useAppDispatch from 'hooks/useAppDispatch';
import { popSuccess } from 'store/slices/popNotifications.slice';
import { VacancyStatus } from 'searchality-data';
import { ReactComponent as ReturnIcon } from 'assets/images/Return.icon.svg';
import { ReactComponent as DoneIcon } from 'icons/Done.icon.svg';

import './JobPreviewHero.styles.scss';
import './JobPreviewHero.styles.responsive.scss';

type JobPreviewHeroProps = {
  className?: string;
  previousPage?: () => void;
  previousPageButtonText?: string;
  data: {
    positionTitle: string;
    isFullTimeEmployment: boolean;
    schools: School[];
    _id: string;
    status: VacancyStatus;
  };
};

const JobPreviewHero: React.FC<JobPreviewHeroProps> = (props) => {
  const { className, data, previousPageButtonText, previousPage } = props;

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const { positionTitle, isFullTimeEmployment, schools, status } = data || {};

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const baseClass = 'job-preview-hero';
  const classes = classNames(baseClass, className);

  const employementType = useMemo(
    () =>
      isFullTimeEmployment ? t('fullTimePosition') : t('partTimePosition'),
    [isFullTimeEmployment, t],
  );

  const jobSchools = useMemo(
    () => schools?.map(({ name }) => name).join(', '),
    [schools],
  );

  return (
    <div className={classes}>
      {previousPage && (
        <Button variant="link" icon={<ReturnIcon />} onClick={previousPage}>
          {previousPageButtonText}
        </Button>
      )}
      {schools?.[0]?.imageFile && (
        <img
          src={schools?.[0]?.imageFile.url}
          alt="logo"
          className="job-preview-hero__logo"
        />
      )}
      <h1 className={`${baseClass}__title`}>{positionTitle}</h1>
      <div className={`${baseClass}__job-information`}>
        <p className={`${baseClass}__job-information__schools`}>{jobSchools}</p>
        <p className={`${baseClass}__job-information__employement-type`}>
          {employementType}
        </p>
      </div>
      {!!data?._id &&
        (status === VacancyStatus.PUBLISHED ||
          status === VacancyStatus.ONGOING) && (
          <div className={`${baseClass}__action-buttons`}>
            <Button
              onClick={() => {
                window.open(
                  `${env.PLATFORM_ENDPOINT}/job-application/${data?._id}`,
                  '_blank',
                );
              }}
            >
              {t('applyNow')}
            </Button>
            <Button
              onClick={() => {
                if (isLinkCopied) return;
                navigator.clipboard.writeText(
                  `${env.PLATFORM_ENDPOINT}/job-application/${data?._id}`,
                );
                dispatch(popSuccess(t('copyToClipboard')));
                setIsLinkCopied(true);
              }}
              className={classNames({ 'link-copied': isLinkCopied })}
              icon={isLinkCopied ? <DoneIcon /> : <DuplicateIcon />}
              iconPosition="right"
              variant="outline"
            >
              {t(isLinkCopied ? 'linkCopied' : 'copyLink')}
            </Button>
          </div>
        )}
      <div className={`${baseClass}__circle`} />
    </div>
  );
};

export default JobPreviewHero;
