import React from 'react';
import HPSettings from '../../components/HiringPreferencesSettings';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';

const HiringPreferencesSettings: React.FC = () => {
  const { t } = useTranslation();
  const { schoolGroupId } = useParams();

  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const { hiringPreferences } = schoolGroup || {};
  const { isFilled } = hiringPreferences || {};

  if (!isFilled) {
    return <p>{t('hiringPreferencesNotSetupBySchool')}</p>;
  }

  return <HPSettings notAccountOwner={true} />;
};

export default HiringPreferencesSettings;
