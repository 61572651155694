import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default () => {
  const { schoolGroupId } = useParams();

  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const { hiringPreferences } = schoolGroup || {};

  const { t } = useTranslation();

  const {
    isTuitionForDependentsOffered,
    dependentsDevelopmentOffered,
    isTravelAllowanceOffered,
    travelAllowanceOffered,
    isFinancialSupportForRelocationOffered,
    financialSupportForRelocationOffered,
    isHousingOffered,
    housingOffered,
    isPrivateHealthInsuranceOffered,
    privateHealthInsuranceOffered,
    isPensionContributionOffered,
    pensionContributionOffered,
    isLeaveAllowanceOffered,
    leaveAllowanceOffered,
    isProfessionalDevelopmentOffered,
    professionalDevelopmentOffered,
    isAdditionalBenefitOffered,
    additionalBenefitOffered,
    studentContactDays,
    studentNonContactDays,
  } = hiringPreferences || {};
  return [
    {
      label: t('SchoolProfileBuilder.Benefits.tuition'),
      value: isTuitionForDependentsOffered
        ? t('SchoolProfileBuilder.Benefits.yes', {
            value: dependentsDevelopmentOffered,
          })
        : t('no'),
    },
    {
      label: t('SchoolProfileBuilder.Benefits.travel'),
      value: isTravelAllowanceOffered
        ? t('SchoolProfileBuilder.Benefits.yes', {
            value: travelAllowanceOffered,
          })
        : t('no'),
    },
    {
      label: t('tuition'),
      value: isFinancialSupportForRelocationOffered
        ? t('SchoolProfileBuilder.Benefits.yes', {
            value: financialSupportForRelocationOffered,
          })
        : t('no'),
    },
    {
      label: t('SchoolProfileBuilder.Benefits.housing'),
      value: isHousingOffered
        ? t('SchoolProfileBuilder.Benefits.yes', {
            value: housingOffered,
          })
        : t('no'),
    },
    {
      label: t('SchoolProfileBuilder.Benefits.insurance'),
      value: isPrivateHealthInsuranceOffered
        ? t('SchoolProfileBuilder.Benefits.yes', {
            value: privateHealthInsuranceOffered,
          })
        : t('no'),
    },
    {
      label: t('SchoolProfileBuilder.Benefits.pension'),
      value: isPensionContributionOffered
        ? t('SchoolProfileBuilder.Benefits.yes', {
            value: pensionContributionOffered,
          })
        : t('no'),
    },
    {
      label: t('SchoolProfileBuilder.Benefits.leave'),
      value: isLeaveAllowanceOffered
        ? t('SchoolProfileBuilder.Benefits.yes', {
            value: leaveAllowanceOffered,
          })
        : t('no'),
    },
    {
      label: t('SchoolProfileBuilder.Benefits.development'),
      value: isProfessionalDevelopmentOffered
        ? t('SchoolProfileBuilder.Benefits.yes', {
            value: professionalDevelopmentOffered,
          })
        : t('no'),
    },
    {
      label: t('benefits'),
      value: isAdditionalBenefitOffered
        ? t('SchoolProfileBuilder.Benefits.yes', {
            value: additionalBenefitOffered,
          })
        : t('no'),
    },
    ...(studentContactDays
      ? [
          {
            label: t('SchoolProfileBuilder.Benefits.student'),
            value: studentContactDays,
          },
        ]
      : []),
    ...(studentNonContactDays
      ? [
          {
            label: t('SchoolProfileBuilder.Benefits.non'),
            value: studentNonContactDays,
          },
        ]
      : []),
  ];
};
