import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import ReviewCardWrapper from 'router/subrouters/Dashboard/components/ReviewCardWrapper';

import './HiringPreferencesSettings.styles.scss';
import './HiringPreferencesSettings.styles.responsive.scss';
import useReviewCardsList from '../../hooks/useReviewCardsList';

type HiringPreferencesSettingsProps = {
  className?: string;
  notAccountOwner?: boolean;
};

const HiringPreferencesSettings: React.FC<HiringPreferencesSettingsProps> = (
  props,
) => {
  const { className, notAccountOwner } = props;

  const navigate = useNavigate();

  const settingsList = useReviewCardsList();

  const classes = classNames('hiring-preferences-settings', className);

  return (
    <div className={classes}>
      {settingsList?.map(({ to, list, title }) => (
        <ReviewCardWrapper
          key={to}
          title={title}
          list={list}
          onEdit={!notAccountOwner ? () => navigate(to) : undefined}
        />
      ))}
    </div>
  );
};

export default HiringPreferencesSettings;
