import { t } from 'i18next';
import { Candidate } from 'models/Candidate';
import { ProfileCardType } from 'models/ProfileCard.type';
import { User } from 'models/User';
import { Vacancy } from 'models/Vacancy';
import { CurriculumExperience, YearsOfExperience } from 'searchality-data';
import {
  JobApplicationFormValues,
  PublicationFormValue,
} from 'types/JobApplication';

function formatPublicationFormIntoPublications(
  publications: PublicationFormValue[],
) {
  return (publications as PublicationFormValue[])?.map(
    ({ url, name, file }, index) => ({
      _id: index.toString(),
      url: url ?? URL.createObjectURL(file?.[0]),
      name,
      key: url ? undefined : 'true',
    }),
  );
}

export function extractProfileInfoFromUser(user: User) {
  const {
    _id,
    firstName,
    lastName,
    email,
    systemColor,
    startDate,
    educationPhilosophyUrl,
    videoUrl,
    education,
    experience,
    curricula,
    sports,
    activities,
    languages,
    englishLevel,
    educationPhilosophyKeyUpdatedAt,
    references,
    imageFile,
    certificates,
    profilePdfUrl,
    publications,
    profileStatus,
    passports,
    maritalStatus,
    isRelocatingWithPartner,
    isWithChildren,
    numberOfChildren,
    birthDate,
    isRelocatingWithPartnerAnswerRejected,
    isWithChildrenAnswerRejected,
  } = user;
  return {
    _id,
    firstName,
    lastName,
    passports,
    email,
    profilePdfUrl,
    systemColor,
    startDate,
    educationPhilosophyUrl,
    educationPhilosophyKeyUpdatedAt,
    videoUrl,
    education,
    experience,
    curricula,
    sports,
    activities,
    languages,
    englishLevel,
    references,
    certificates,
    publications,
    profileStatus,
    teacherId: _id,
    imageUrl: imageFile?.url,
    maritalStatus,
    isRelocatingWithPartner,
    isWithChildren,
    numberOfChildren,
    birthDate,
    isRelocatingWithPartnerAnswerRejected,
    isWithChildrenAnswerRejected,
  } as unknown as ProfileCardType;
}

export function extractProfileInfoFromJobApplication(
  values: JobApplicationFormValues,
  candidate?: User,
  vacancy?: Vacancy,
) {
  if (JSON.stringify(values) === '{}') return;

  const {
    startDate: jobStartingDate,
    curriculumExperience: vacancyCurricullumExperience,
    educationLevel,
    yearsOfExperienceRequired,
  } = vacancy;

  const {
    personalInformation: {
      firstName,
      lastName,
      email,
      phoneNumber: mobilePhone,
      address,
      videoUrl,
      applicantPhoto,
      citizenships,
    } = {},

    relocation: {
      maritalStatus,
      isRelocatingWithPartner,
      isWithChildren,
      numberOfChildren,
    } = {},

    yourProfile: {
      education,
      experience,
      educationPhilosophy,
      coverLetter,
      resume,
    } = {},
    positionRequirements: {
      languageRequirement: languages,
      isAbleToStartOnRequiredDate,
      earliestAvailableStartingDate,
      yearsOfExperienceWithFaithBasedSchools,
      doesHaveExperinceWithFaithBasedSchools,
      doesHaveExperienceInTheField,
      yearsOfExperienceInTheField,
      englishLevel,
      doesMeetMinimumEducationLevel,
      doesHaveYearsOfExperience,
    } = {},
    references,
    publications,
  } = values || ({} as JobApplicationFormValues);

  const cardInfo: ProfileCardType = {
    firstName,
    lastName,
    email,
    mobilePhone,
    address,
    videoUrl,
    englishLevel,
    education,
    maritalStatus,
    passports: citizenships,
    isRelocatingWithPartner,
    isWithChildren,
    numberOfChildren: parseInt(numberOfChildren),
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    doesHaveYearsOfExperience,
    doesMeetMinimumEducationLevel,
    ...(doesMeetMinimumEducationLevel && { educationLevel }),
    ...(yearsOfExperienceRequired && { yearsOfExperienceRequired }),
    references,
    experience,
    resumeUrl: resume?.length ? URL.createObjectURL(resume[0]) : undefined,
    educationPhilosophyUrl: educationPhilosophy?.length
      ? URL.createObjectURL(educationPhilosophy[0])
      : undefined,
    coverLetterUrl: coverLetter?.length
      ? URL.createObjectURL(coverLetter[0])
      : undefined,
    languages: Object.keys(languages || {}).map((key) => ({
      language: key,
      level: languages[key].level ? languages[key].level : undefined,
      doesSpeak: languages[key].level ? true : false,
    })),
    imageUrl: applicantPhoto?.length
      ? URL.createObjectURL(applicantPhoto[0])
      : undefined,
    systemColor: '',
    startDate: isAbleToStartOnRequiredDate
      ? jobStartingDate ?? null
      : earliestAvailableStartingDate,
    sports: candidate?.sports ?? undefined,
    activities: candidate?.activities ?? undefined,
    curricula: doesHaveExperienceInTheField
      ? [
          {
            yearsOfExperience: yearsOfExperienceInTheField,
            curriculumExperience:
              vacancyCurricullumExperience as CurriculumExperience,
          },
        ]
      : undefined,
    certificates: candidate?.certificates,
    publications: formatPublicationFormIntoPublications(publications),
  };

  return cardInfo;
}

export function extractProfileInfoFromCandidate(candidate: Candidate) {
  if (JSON.stringify(candidate) === '{}') return;

  const { vacancy } = candidate || {};

  const {
    startDate,
    curriculumExperience: vacancyCurricula,
    educationLevel,
    yearsOfExperienceRequired,
  } = vacancy || {};

  const {
    _id,
    firstName,
    lastName,
    email,
    educationPhilosophyUrl,
    educationPhilosophyKeyUpdatedAt,
    coverLetterKeyUpdatedAt,
    resumeKeyUpdatedAt,
    coverLetterUrl,
    videoUrl,
    resumeUrl,
    education,
    type,
    earliestAvailableStartingDate,
    isAbleToStartOnRequiredDate,
    experience,
    address,
    references,
    yearsOfExperienceInTheField,
    doesHaveExperienceInTheField,
    doesHaveExperinceWithFaithBasedSchools,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveYearsOfExperience,
    doesMeetMinimumEducationLevel,
    imageFile,
    color,
    updatedAt,
    isFavourite,
    teacher,
    englishLevel,
    publications,
    languageRequirement,
    maritalStatus,
    numberOfChildren,
    isWithChildren,
    isRelocatingWithPartner,
    citizenships,
    hasRightToWork,
    schools,
    isRelocationNotRequired,
  } = candidate;

  const cardInfo: ProfileCardType = {
    maritalStatus,
    numberOfChildren,
    isWithChildren,
    isRelocatingWithPartner,
    hasRightToWork,
    isRelocationNotRequired,
    country: schools?.[0]?.country,
    passports: citizenships,
    firstName,
    _id,
    lastName,
    email,
    videoUrl,
    resumeUrl,
    educationPhilosophyKeyUpdatedAt,
    resumeKeyUpdatedAt,
    coverLetterKeyUpdatedAt,
    doesHaveYearsOfExperience,
    doesMeetMinimumEducationLevel,
    type,
    education,
    references,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    experience,
    educationPhilosophyUrl,
    ...(doesMeetMinimumEducationLevel && { educationLevel }),
    ...(yearsOfExperienceRequired && { yearsOfExperienceRequired }),
    coverLetterUrl,
    imageUrl: imageFile?.url,
    address,
    systemColor: color,
    startDate: isAbleToStartOnRequiredDate
      ? startDate
      : earliestAvailableStartingDate,
    updatedAt,
    isFavourite,
    curricula: vacancyCurricula
      ? ([
          {
            yearsOfExperience: doesHaveExperienceInTheField
              ? (yearsOfExperienceInTheField?.toString() as YearsOfExperience)
              : (t('haveNoExperience') as YearsOfExperience),
            curriculumExperience: vacancyCurricula,
          },
        ] as any)
      : [],
    englishLevel,
    languages: languageRequirement,
    sports: teacher?.sports,
    activities: teacher?.activities,
    certificates: teacher?.certificates,
    publications,
  };

  return cardInfo;
}
