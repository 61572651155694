import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import AdminsPage from './pages/Admins';
import UsersFeedback from './pages/UsersFeedback';
import MyAccount from './subrouters/MyAccount/MyAccount.router';
import MyAccountRoutes from './subrouters/MyAccount/MyAccount.routes';
import SchoolGroupsRouter from './subrouters/SchoolGroups/SchoolGroups.router';
import SchoolGroupsRoutes from './subrouters/SchoolGroups/SchoolGroups.routes';
import Teachers from './subrouters/Teachers/Teachers.router';
import TeachersRoutes from './subrouters/Teachers/Teachers.routes';

export default [
  {
    path: 'school-groups',
    element: SchoolGroupsRouter,
    routes: SchoolGroupsRoutes,
  },
  {
    path: 'teachers',
    element: Teachers,
    routes: TeachersRoutes,
  },
  {
    path: 'admins',
    element: AdminsPage,
  },
  {
    path: 'my-account',
    element: MyAccount,
    routes: MyAccountRoutes,
  },
  {
    path: 'users-feedback',
    element: UsersFeedback,
  },
  {
    index: true,
    element: <Navigate to="school-groups" />,
  },
] as Array<ComplexRoute>;
