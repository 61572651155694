import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import GroupSettings from './pages/GroupSettings';
import PaymentSettings from './pages/PaymentSettings';
import SchoolProfile from './pages/SchoolProfile';
import SchoolSettings from './pages/SchoolSettings';
import WidgetSettings from './pages/WidgetSettings';
import HiringPreferencesSettings from './pages/HiringPreferencesSettings';

export default [
  {
    path: 'group-settings',
    element: GroupSettings,
  },
  {
    path: 'school-settings',
    element: SchoolSettings,
  },
  {
    path: 'school-settings/:schoolId',
    element: SchoolProfile,
  },
  {
    path: 'payment',
    element: PaymentSettings,
  },
  {
    path: 'widget-status',
    element: WidgetSettings,
  },
  {
    path: 'hiring-preferences',
    element: HiringPreferencesSettings,
  },
  {
    index: true,
    element: <Navigate to="group-settings" replace />,
  },
] as Array<ComplexRoute>;
