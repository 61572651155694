import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiringPreferences } from 'models/School';
import { useParams } from 'react-router-dom';
import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';

export default () => {
  const { t } = useTranslation();

  const { schoolGroupId } = useParams();

  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const { hiringPreferences } = schoolGroup || {};

  const mapBooleanToTranslation = useCallback(
    (value: boolean) => {
      return value ? t('yes') : t('no');
    },
    [t],
  );

  const getHiringPreferencesFromSG = (values: HiringPreferences) => {
    const {
      isAcceptingSingleCandidates,
      isAcceptingSingleWithNonTeachingPartner,
      isAcceptingSingleWithTeachingPartner,
      isAcceptingMarriedWithNonTeachingPartner,
      isAcceptingMarriedWithTeachingPartner,
      isAcceptingWithChildren,
    } = values;

    return {
      isAcceptingSingleCandidates,
      isAcceptingSingleWithNonTeachingPartner,
      isAcceptingSingleWithTeachingPartner,
      isAcceptingMarriedWithNonTeachingPartner,
      isAcceptingMarriedWithTeachingPartner,
      isAcceptingWithChildren,
    };
  };

  const list = useMemo(() => {
    const hiringPreferencesFields =
      getHiringPreferencesFromSG(hiringPreferences);

    return Object.values(hiringPreferencesFields).map((value, index) => ({
      label: t(`SchoolProfileBuilder.HiringPreferences.label${index + 1}`),
      value: mapBooleanToTranslation(value),
    }));
  }, [hiringPreferences, mapBooleanToTranslation, t]);

  return list;
};
