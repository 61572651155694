import React from 'react';
import { SchoolGroup } from 'models/School';
import { ReactComponent as DuplicateIcon } from 'icons/Duplicate.icon.svg';
import useAppDispatch from 'hooks/useAppDispatch';
import { popSuccess } from 'store/slices/popNotifications.slice';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';
import { SchoolGroupStatus } from 'searchality-data';
import classNames from 'classnames';
import utils from 'utils';

const useSchoolsGroupTableData = (schoolGroups: SchoolGroup[]) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return schoolGroups?.map(
    ({
      _id,
      name,
      curriculumType,
      residentialType,
      status,
      createdAt,
      website,
      schools,
      matchingInformation,
      lastLogin,
    }) => ({
      id: _id,
      data: {
        name,
        createdAt: DateService.getUSADateFormatLong(createdAt),
        lastLogin: lastLogin
          ? DateService.getUSADateFormatLong(lastLogin)
          : t('noData'),
        _id: (
          <button
            className="svg-button-wrapper"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(_id);
              dispatch(popSuccess(t('copyToClipboardSuccess')));
            }}
          >
            <DuplicateIcon />
            {_id}
          </button>
        ),
        curriculumType: utils.capitalizeFirstLetter(curriculumType),
        residentialType: utils.capitalizeFirstLetter(residentialType),
        country: schools?.[0]?.country,
        city: schools?.[0]?.city,
        website,
        status: (
          <p
            className={classNames('searchality-admin-schools--active-column', {
              'searchality-admin-schools--active-column--inactive':
                status !== SchoolGroupStatus.ACTIVE,
            })}
          >
            {status}
          </p>
        ),
        accountType: matchingInformation?.isForTesting ? t('testing') : '',
      },
    }),
  );
};

export default useSchoolsGroupTableData;
