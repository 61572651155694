import { useTranslation } from 'react-i18next';

const useTeachersTableHeader = () => {
  const { t } = useTranslation();

  return [
    {
      text: t('name'),
      value: 'firstName lastName',
      sortable: true,
    },
    {
      text: t('createdAt'),
      value: 'createdAt',
    },
    {
      text: t('lastLogin'),
      value: 'lastLogin',
      sortable: true,
    },
    {
      text: t('id'),
      value: '_id',
      sortable: true,
    },
    {
      text: t('email'),
      value: 'email',
      sortable: true,
    },
    {
      text: t('accountType'),
      value: 'accountType',
    },
    {
      text: t('englishLevel'),
      value: 'englishLevel',
      sortable: true,
    },
  ];
};

export default useTeachersTableHeader;
