import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Vacancy } from 'models/Vacancy';
import DateService from 'services/Date.service';
import { useTranslation } from 'react-i18next';
import { VacancyStatus, VacancyType } from 'searchality-data';
import BasicTag from 'components/BasicTag';
import { BasicTagVariants } from 'components/BasicTag/BasicTag.component';
import { School } from 'models/School';
import { ReactComponent as SchoolIcon } from 'icons/Education.icon.svg';
import { ReactComponent as WorkIcon } from 'icons/Work.icon.svg';

import './VacancyCard.styles.scss';

export type VacancyCardProps = Vacancy & {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onEdit?: (e: React.MouseEvent<HTMLElement>) => void;
  noActions?: boolean;
  noArchive?: boolean;
  noEdit?: boolean;
  className?: string;
  isSelected?: boolean;
  applicationSchools?: School[];
  applicationCreatedAt?: string;
};

const basicTagColorMapping: { [name in VacancyStatus]: BasicTagVariants } = {
  Published: 'blue',
  Draft: 'orange',
  Archived: 'lightGrey',
  Ongoing: 'blue',
  Filled: 'blue',
  Expired: 'lightGrey',
  Closed: 'lightGrey',
};

const VacancyCard: React.FC<VacancyCardProps> = (props) => {
  const {
    className,
    onClick,
    onEdit,
    noActions,
    noArchive,
    noEdit,
    applicationSchools,
    applicationCreatedAt,
    isSelected = false,
    ...vacancy
  } = props;

  const {
    schools,
    publishedAt,
    createdAt,
    positionDivision,
    positionTitle,
    subjectGradeLevel,
    status,
    type,
    category,
  } = vacancy;

  const { t } = useTranslation();

  const classes = classNames('searchality-vacancy-card', className, {
    'searchality-vacancy-card--active': isSelected,
    'searchality-vacancy-card--deleted': !vacancy?._id,
  });

  const getListOfSchools = useCallback(
    (schools: School[]) => schools?.map(({ name }) => name).join('/'),
    [],
  );

  const schoolNamesList = useMemo(() => {
    if (!schools?.length) return;
    return (
      <ul>
        {schools?.map(({ name, _id }) => (
          <li key={_id}>
            <p>{name}</p>
          </li>
        ))}
      </ul>
    );
  }, [schools]);

  const isVacancyPublished = useMemo(() => status === 'Published', [status]);

  return (
    <li
      className={classes}
      onClick={(e) => {
        vacancy?._id && onClick?.(e);
      }}
    >
      {vacancy?._id ? (
        <>
          <div className="searchality-vacancy-card__left">
            <p className="searchality-vacancy-card__left__school-type">
              {type === VacancyType.ACADEMIC ? (
                <>
                  <SchoolIcon />
                  {positionDivision}
                </>
              ) : (
                <>
                  <WorkIcon />
                </>
              )}
            </p>
            <p className="searchality-vacancy-card__left__position">
              {positionTitle}
            </p>
            <span className="searchality-vacancy-card__left__type">
              {type === VacancyType.ACADEMIC ? subjectGradeLevel : category}
            </span>
            <span className="searchality-vacancy-card__left__school-name">
              {schoolNamesList}
            </span>
          </div>
          <div className="searchality-vacancy-card__right">
            <div>
              <label>{t('status')}</label>
              <BasicTag
                text={status}
                variant={basicTagColorMapping[status as VacancyStatus]}
              />
            </div>
            <span className="searchality-vacancy-card__right__status-date">{`${
              isVacancyPublished ? 'Published' : 'Created'
            } on ${DateService.fullShorterDate(
              isVacancyPublished ? publishedAt : createdAt,
            )}`}</span>
          </div>
        </>
      ) : (
        <p className="searchality-vacancy-card__deleted-vacancy">
          {t('vacancyForApplicationIsDeleted', {
            applicationCreatedAt: DateService.formatDate(applicationCreatedAt),
            listOfSchools: getListOfSchools(applicationSchools),
          })}
        </p>
      )}
    </li>
  );
};

export default VacancyCard;
