import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { adminsApi } from 'api/admins.api';
import { associationsApi } from 'api/associations.api';
import { authApi } from 'api/auth.api';
import { schoolGroupsApi } from 'api/schoolGroups.api';
import { teachersApi } from 'api/teachers.api';
import { authMiddleware } from 'middlewares/auth.middleware';
import requestParamsMiddleware from 'middlewares/requestParams.middleware';
import credentialsService from 'services/credentialsService';
import { createParamsReducer } from './reducer/params.reducer';
import popNotificationsSlice from './slices/popNotifications.slice';

const allReducers = combineReducers({
  schoolGroupsParams: createParamsReducer('schoolGroupsParams', {
    $limit: 20,
    $populate: ['schools'],
  }),
  schoolGroupVacancies: createParamsReducer('schoolGroupVacancies', {
    $limit: 6,
    $populate: ['schools'],
  }),
  schoolGroupMembers: createParamsReducer('schoolGroupMembers', {
    $limit: 20,
    $populate: ['schools'],
  }),
  schoolGroupVacancyCandidates: createParamsReducer(
    'schoolGroupVacancyCandidates',
    { $limit: 6 },
  ),
  candidateCommentsParams: createParamsReducer('candidateCommentsParams', {
    $limit: 6,
    $populate: ['author'],
  }),
  teachersParams: createParamsReducer('teachersParams', {
    $limit: 20,
  }),
  associationsParams: createParamsReducer('associationsParams', {
    $limit: 20,
  }),
  associationSchoolsParams: createParamsReducer('associationSchoolsParams', {
    $limit: 6,
    $populate: ['schoolGroup'],
  }),
  teacherApplicationsParams: createParamsReducer('teacherApplicationsParams', {
    $limit: 6,
    $populate: ['vacancy', 'schools', 'vacancy.schools'],
  }),
  [teachersApi.reducerPath]: teachersApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [schoolGroupsApi.reducerPath]: schoolGroupsApi.reducer,
  [associationsApi.reducerPath]: associationsApi.reducer,
  adminsParams: createParamsReducer('adminsParams', {
    $limit: 10,
  }),
  [adminsApi.reducerPath]: adminsApi.reducer,
  popNotifications: popNotificationsSlice,
});

const adminStore = configureStore({
  reducer: (state: ReturnType<typeof allReducers>, action: AnyAction) => {
    return allReducers(state, action);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authMiddleware,
      schoolGroupsApi.middleware,
      authApi.middleware,
      requestParamsMiddleware,
      adminsApi.middleware,
      teachersApi.middleware,
      associationsApi.middleware,
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof adminStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof adminStore.dispatch;

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

credentialsService.token &&
  credentialsService.refreshToken &&
  adminStore.dispatch(authApi.endpoints.loadCurrentUser.initiate());

export default adminStore;
