import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';
import { useParams } from 'react-router-dom';
import { SchoolGroupStructureType } from 'searchality-data';

const useSchoolSettingsTabs = () => {
  const { schoolGroupId } = useParams();
  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);
  return [
    {
      to: `/school-groups/${schoolGroupId}/account/group-settings`,
      label:
        schoolGroup?.structureType === SchoolGroupStructureType.GROUP
          ? 'groupSettings'
          : 'schoolSettings',
    },
    ...(schoolGroup?.structureType === SchoolGroupStructureType.GROUP
      ? [
          {
            to: `/school-groups/${schoolGroupId}/account/school-settings`,
            label: 'schoolSettings',
          },
        ]
      : []),
    {
      to: `/school-groups/${schoolGroupId}/account/payment`,
      label: 'payments',
    },
    {
      to: `/school-groups/${schoolGroupId}/account/widget-status`,
      label: 'widgetStatus',
    },
    {
      to: `/school-groups/${schoolGroupId}/account/hiring-preferences`,
      label: 'hiringPreferences',
    },
  ];
};

export default useSchoolSettingsTabs;
