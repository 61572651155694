import { useTranslation } from 'react-i18next';
import {} from 'searchality-data';
import utils from 'utils';
import { useParams } from 'react-router-dom';
import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';

export default () => {
  const { schoolGroupId } = useParams();

  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const { hiringPreferences } = schoolGroup || {};

  const { t } = useTranslation();

  const convertUnitToTranslation = (contractDurcation: any) => {
    const { value, unit } = contractDurcation;
    if (value > 1) {
      return `${value} ${unit}`;
    }

    return `${value} ${unit.slice(0, -1)}`;
  };

  const {
    startingContractDuration,
    subsequentContractDuration,
    contractRenewalDate,
    teacherContractedWorkingDays,
    adminContractedWorkingDays,
    teacherStartDate,
    teacherEndDate,
    adminStartDate,
    adminEndDate,
  } = hiringPreferences || {};

  return [
    {
      label: t('SchoolProfileBuilder.CI.startingContractDuration'),
      value: convertUnitToTranslation(startingContractDuration),
    },
    {
      label: t('SchoolProfileBuilder.CI.subsequentContractDuration'),
      value: convertUnitToTranslation(subsequentContractDuration),
    },
    {
      label: t('SchoolProfileBuilder.CI.contractRenewalDate'),
      value:
        utils.convertToOrdinal(contractRenewalDate.day) +
        ' ' +
        contractRenewalDate.month,
    },
    {
      label: t('SchoolProfileBuilder.CI.teacherContractedWorkingDays'),
      value: teacherContractedWorkingDays,
    },
    {
      label: t('SchoolProfileBuilder.CI.adminContractedWorkingDays'),
      value: adminContractedWorkingDays,
    },
    {
      label: t('SchoolProfileBuilder.CI.teacherStartDate'),
      value:
        utils.convertToOrdinal(teacherStartDate.day) +
        ' ' +
        teacherStartDate.month,
    },
    {
      label: t('SchoolProfileBuilder.CI.teacherEndDate'),
      value:
        utils.convertToOrdinal(teacherEndDate.day) + ' ' + teacherEndDate.month,
    },
    {
      label: t('SchoolProfileBuilder.CI.adminStartDate'),
      value:
        utils.convertToOrdinal(adminStartDate.day) + ' ' + adminStartDate.month,
    },
    {
      label: t('SchoolProfileBuilder.CI.adminEndDate'),
      value:
        utils.convertToOrdinal(adminEndDate.day) + ' ' + adminEndDate.month,
    },
  ];
};
