import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';

export default () => {
  const { t } = useTranslation();

  const { schoolGroupId } = useParams();

  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const { hiringPreferences } = schoolGroup || {};

  const {
    minimumAge,
    maximumAge,
    educationalLevelRequired,
    minimumYearsOfExperienceRequired,
    healthRequirements,
    preferredPassports,
  } = hiringPreferences || {};

  const mapBooleanToTranslation = useCallback(
    (value: boolean) => {
      return value ? t('yes') : t('no');
    },
    [t],
  );

  const renderConditionalField = useCallback(
    (label: string, value: any) => {
      if (value !== undefined) {
        return [
          {
            label,
            value:
              typeof value === 'boolean'
                ? mapBooleanToTranslation(value)
                : value,
          },
        ];
      }

      return [
        {
          label,
          value: t('notApplicable'),
        },
      ];
    },
    [mapBooleanToTranslation, t],
  );

  return [
    ...renderConditionalField(
      t('SchoolProfileBuilder.HCR.minAgeLabel'),
      minimumAge,
    ),
    ...renderConditionalField(
      t('SchoolProfileBuilder.HCR.maxAgeLabel'),
      maximumAge,
    ),
    ...renderConditionalField(
      t('SchoolProfileBuilder.HCR.educationLevelLabel'),
      educationalLevelRequired,
    ),
    ...renderConditionalField(
      t('SchoolProfileBuilder.HCR.yearsOfExperienceLabel'),
      minimumYearsOfExperienceRequired,
    ),
    ...renderConditionalField(
      t('SchoolProfileBuilder.HCR.healthRequirementsLabel'),
      healthRequirements,
    ),
    {
      label: t('SchoolProfileBuilder.HCR.preferredAdditional'),
      value: preferredPassports?.length
        ? `${t('yes')}. ${preferredPassports?.join(', ')}`
        : t('no'),
    },
  ];
};
