import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Role, UserStatus } from 'searchality-data';
import MemberNameTableData from '../MemberInfo';
import utils, { getNameInitials } from 'utils';
import { useTranslation } from 'react-i18next';
import { School } from 'models/School';
import { User } from 'models/User';
import { useNavigate } from 'react-router-dom';
import RolesSelect from '../RolesSelect';
import useMembersTableHeader from 'router/subrouters/Dashboard/hooks/useMembersTableHeader';
import PendingInvite from '../PendingInvite';

import './MembersTable.styles.scss';

type MembersTableProps = {
  className?: string;
  members: User[];
  isReadonly?: boolean;
  schoolName?: string;
  isAssociation?: boolean;
};

const MembersTable: React.FC<MembersTableProps> = (props) => {
  const {
    className,
    members,
    schoolName,
    isReadonly = false,
    isAssociation,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableHeader = useMembersTableHeader(isAssociation);

  const classes = classNames('members-table', className);

  const schoolTableValue = useCallback(
    (status: UserStatus, role: Role, schools?: School[]) => {
      if (schoolName) return schoolName;

      if (status === UserStatus.PENDING || role === Role.Reviewer) return '';

      if (role === Role.AccountOwner || role === Role.AccountManager)
        return t('all');

      return schools?.[0]?.name;
    },
    [schoolName, t],
  );

  const headerMapped = useMemo(
    () =>
      tableHeader?.map(({ id, label, key }) => (
        <th key={id} className={`'members-table__column__${key}`}>
          {label}
        </th>
      )),
    [tableHeader],
  );

  return (
    <table className={classes}>
      <thead>
        <tr>{headerMapped}</tr>
      </thead>
      <tbody>
        {members?.map(
          ({
            _id,
            firstName,
            lastName,
            imageFile,
            email,
            systemColor,
            status,
            role,
            invitedAt,
            schools,
            schoolGroupId,
          }) => {
            const isPending = status === UserStatus.PENDING;

            return (
              <tr key={_id}>
                <td className={`members-table__column__name`}>
                  <MemberNameTableData
                    placeholderText={
                      !isPending ? getNameInitials(firstName, lastName) : 'NN'
                    }
                    email={email}
                    src={imageFile?.url}
                    backgroundColor={systemColor}
                    name={
                      !isPending &&
                      utils.trimLongName(`${firstName} ${lastName}`, 30)
                    }
                    className={classNames({
                      'members-table__column__name--clickable': !(
                        isReadonly || isPending
                      ),
                    })}
                    onClick={() =>
                      isReadonly || isPending ? undefined : navigate(`${_id}`)
                    }
                    invitedAt={invitedAt}
                    displayRemaingTime={false}
                  />
                </td>
                {!isAssociation && (
                  <td className={'members-table__column__school'}>
                    <p>{schoolTableValue(status, role, schools)}</p>
                  </td>
                )}
                <td className={'members-table__column__role'}>
                  {isPending ? (
                    <PendingInvite
                      memberId={_id}
                      schoolGroupId={schoolGroupId}
                    />
                  ) : (
                    <RolesSelect
                      currentRole={role}
                      userId={_id}
                      isReadonly={isReadonly}
                    />
                  )}
                </td>
              </tr>
            );
          },
        )}
      </tbody>
    </table>
  );
};

export default MembersTable;
