import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SportIcon } from 'icons/Sport.icon.svg';
import { ProfileCardType } from 'models/ProfileCard.type';
import ProfileLanguagesSection from '../ProfileLanguagesSection';
import ProfilePublications from '../ProfilePublications';

import './ProfileLanguages.styles.scss';

type ProfileLanguagesProps = {
  className?: string;
} & Pick<
  ProfileCardType,
  'languages' | 'englishLevel' | 'sports' | 'activities' | 'publications'
>;

const ProfileLanguages: React.FC<ProfileLanguagesProps> = (props) => {
  const {
    className,
    languages,
    englishLevel,
    sports,
    activities,
    publications,
  } = props;

  const { t } = useTranslation();

  const baseClass = 'searchality-profile-languages';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <ProfileLanguagesSection
        baseClass={baseClass}
        languages={languages}
        englishLevel={englishLevel}
      />
      {(!!sports?.length || !!activities?.length) && (
        <>
          <hr />
          <div className="profile-section">
            <div className="profile-section__title">
              <SportIcon />
              <p>{t('Profile.Languages.sports')}</p>
            </div>
            <div className={`${baseClass}__section`}>
              {!!sports?.length && (
                <>
                  <p className="title">{t('sports')}</p>
                  <p className="subtitle">{sports?.join(', ')}</p>
                </>
              )}
              {!!activities?.length && (
                <>
                  <p className="title">{t('activities')}</p>
                  <p className="subtitle">{activities?.join(', ')}</p>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {!!publications?.length && (
        <>
          <hr />
          <ProfilePublications publications={publications} />
        </>
      )}
    </div>
  );
};

export default ProfileLanguages;
