import React, { ReactNode } from 'react';
import { ReactComponent as Vacancies } from 'icons/Vacancy.icon.svg';
import { ReactComponent as Members } from 'icons/Members.icon.svg';
import { ReactComponent as Candidate } from 'icons/Candidate.icon.svg';
import { ReactComponent as Settings } from 'icons/Settings.icon.svg';
import { ReactComponent as UserFeedback } from 'icons/UserFeedback.icon.svg';

type HeaderItem = {
  to: string;
  label: string;
  Icon: ReactNode;
};

const headerItems: HeaderItem[] = [
  {
    to: '/school-groups',
    label: 'schools',
    Icon: <Vacancies />,
  },
  {
    to: '/teachers',
    label: 'teachers',
    Icon: <Candidate />,
  },
  {
    to: '/admins',
    label: 'admins',
    Icon: <Members />,
  },
  {
    to: '/users-feedback',
    label: 'usersFeedback',
    Icon: <UserFeedback />,
  },
  {
    to: '/my-account',
    label: 'myAccount',
    Icon: <Settings />,
  },
];

export default function () {
  return headerItems;
}
