import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { ReactComponent as CheckIcon } from 'icons/CheckMark.icon.svg';
import { ReactComponent as DropdownArrowIcon } from 'icons/dropdown-arrow.icon.svg';
import { useTranslation } from 'react-i18next';
import { CollapsibleRef } from 'ncoded-component-library/build/components/molecules/Collapsible/Collapsible.component';
import { Collapsible } from 'ncoded-component-library';

import './CandidateProfileRtw.styles.scss';
import { useGetCandidateQuery } from 'api/schoolGroups.api';
import CandidateQualification from '../../pages/VacancyCandidates/components/CandidateQualification';
import CandidateRequirement from '../CandidateRequirement';

type CandidateProfileRtwProps = {
  className?: string;
};

const CandidateProfileRtw: React.FC<CandidateProfileRtwProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const { candidateId, schoolGroupId } = useParams();
  const dropdownRef = useRef<CollapsibleRef>(null);

  const { data: candidate } = useGetCandidateQuery({
    schoolGroupId,
    candidateId,
  });

  const { vacancy, hasRightToWork, citizenships } = candidate || {};

  const {
    minimumAge,
    maximumAge,
    minimumYearsOfExperienceRequired,
    educationalLevelRequired,
    healthRequirements,
    preferredPassports,
  } = vacancy || {};

  const classes = classNames('candidate-profile-rtw', className);

  const requirementsData = useMemo(() => {
    const requirements = [];

    if (minimumAge && maximumAge) {
      requirements.push({
        fitCriteria: true,
        title: t('RTW.Age.title'),
        requirement: t('RTW.Age.req', { minimumAge, maximumAge }),
      });
    }
    if (educationalLevelRequired) {
      requirements.push({
        fitCriteria: true,
        title: t('RTW.Education.title'),
        requirement: t('RTW.Education.req', { educationalLevelRequired }),
      });
    }
    if (minimumYearsOfExperienceRequired) {
      requirements.push({
        fitCriteria: true,
        title: t('RTW.Experience.title'),
        requirement: t('RTW.Experience.req', {
          minimumYearsOfExperienceRequired,
        }),
      });
    }

    if (healthRequirements) {
      requirements.push({
        fitCriteria: true,
        title: t('RTW.Health.title'),
        requirement: t('RTW.Health.req', { healthRequirements }),
      });
    }

    const country = preferredPassports?.filter((country) =>
      citizenships?.includes(country),
    );

    if (country?.length) {
      requirements.push({
        fitCriteria: true,
        title: t('RTW.Passport.title'),
        requirement: t('RTW.Passport.req', { country: country?.[0] }),
      });
    } else {
      requirements.push({
        fitCriteria: false,
        title: t('RTW.Passport.title'),
        requirement: t('RTW.Passport.reqDont', { country: country?.[0] }),
      });
    }

    return requirements;
  }, [
    citizenships,
    educationalLevelRequired,
    healthRequirements,
    maximumAge,
    minimumAge,
    minimumYearsOfExperienceRequired,
    preferredPassports,
    t,
  ]);

  if (hasRightToWork) {
    return (
      <div className={'candidate-profile-rtw--work-permit'}>
        <div>
          <p>{t('rightToWork')}</p>
          <CheckIcon />
        </div>
        <p>{t('candidateHoldsRtw')}</p>
      </div>
    );
  }

  return (
    <Collapsible
      ref={dropdownRef}
      className={classes}
      trigger={
        <>
          <div className="collapsabile__title">
            <p>{t('rightToWork')}</p>
            <DropdownArrowIcon />
          </div>
          <CandidateQualification
            qualificationsNumber={requirementsData?.length}
            candidateQualifications={
              requirementsData?.filter((req) => req.fitCriteria).length
            }
            variant={
              requirementsData?.filter((req) => req.fitCriteria).length ===
              requirementsData?.length
                ? 'green'
                : 'blue'
            }
            showTitle={false}
          />
          <p>{t('RTW.req')}</p>
        </>
      }
    >
      <div className={`candidate-profile-rtw__content`}>
        {requirementsData?.map((element) => (
          <CandidateRequirement key={element.title} {...element} />
        ))}
      </div>
    </Collapsible>
  );
};

export default CandidateProfileRtw;
