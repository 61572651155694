import { useTranslation } from 'react-i18next';

const useTableHeader = () => {
  const { t } = useTranslation();

  return [
    {
      text: t('name'),
      value: 'name',
      sortable: true,
    },
    {
      text: t('createdAt'),
      value: 'createdAt',
      sortable: true,
    },
    {
      text: t('lastLogin'),
      value: 'lastLogin',
      sortable: true,
    },
    {
      text: t('id'),
      value: '_id',
      sortable: true,
    },
    {
      text: t('curriculumType'),
      value: 'curriculumType',
      sortable: true,
    },
    {
      text: t('schoolType'),
      value: 'residentialType',
      sortable: true,
    },
    {
      text: t('country'),
      value: 'country',
      sortable: true,
    },
    {
      text: t('city'),
      value: 'city',
      sortable: true,
    },
    {
      text: t('website'),
      value: 'website',
      sortable: true,
    },
    {
      text: t('accountStatus'),
      value: 'status',
      sortable: true,
    },
    {
      text: t('accountType'),
      value: 'accountType',
      sortable: true,
    },
  ];
};

export default useTableHeader;
